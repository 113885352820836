<template>
  <div class="ma-10">
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectDivision"
              :items="filterDivisions"
              @input="changeDivision"
              label="事業部"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="selectFilterAffiliations"
              :items="filterAffiliations"
              label="所属"
              dense
              multiple
              chips
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn
          icon="mdi-pencil"
          color="success"
          text
          outlined
          @click="selectDetails"
          :disabled="!selected.length"
        >
          選択事業所の設定
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        :items-per-page="-1"
        multi-sort
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <Btn color="info" text small @click="details(item)">確認</Btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { CODE_GROUP as CodeGroup } from "@/defines";

export default {
  name: "ApprovalList",
  mixins: [Common, ShowDialogs, Api],
  props: { args: Object },
  data() {
    return {
      params: null,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      selectDivision: {},
      filterDivisions: [],
      filterAffiliations: [],
      selectFilterAffiliations: [],
      affiliationsItems: [],
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async select() {
      console.log("select");

      const param = {
        code1: this.selectDivision,
        affiliationIds: this.selectFilterAffiliations
      };
      const encode = encodeURI(JSON.stringify(param));
      const data = await this.$get(this.Paths.approvalList, "query=" + encode);
      await this.setdata(data);
    },
    async getData() {
      console.log("getData");
      const data = await this.$get(this.Paths.approvalList);
      await this.setdata(data);
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    },
    async getSearchCondition() {
      console.log("getSearchCondition", this.$store.state.shozokuCode1);
      const codeMasters = await this.$get(this.Paths.codeMasterPulldown);
      this.filterDivisions = codeMasters.filter(
        e => e.refcode === CodeGroup.DIVISI
      );
      if (this.$store.state.shozokuCode1) {
        this.selectDivision = this.$store.state.shozokuCode1;
      } else {
        this.selectDivision = "1";
      }

      this.affiliationsItems = await this.$get(this.Paths.shozoku);
      this.filterAffiliations = this.affiliationsItems.filter(
        e => e.refcode === this.selectDivision
      );
    },
    changeDivision() {
      console.log("changeDivision", this.selectDivision);
      this.filterAffiliations = this.affiliationsItems.filter(
        e => e.refcode === this.selectDivision
      );
    },
    details(item) {
      console.log("details", item);
      const target = [];
      target.push(item);

      const prosess = "edit";
      const params = {
        title: this.title,
        prosess,
        items: this.controls,
        args: {
          targetItems: target,
          selectDivision: this.selectDivision,
          selectFilterAffiliations: this.selectFilterAffiliations
        }
      };
      this.$router.push({ name: "ApprovalDetails", params });
    },
    addDetails() {
      console.log("addDetails");
      const prosess = "add";
      const params = {
        title: this.title,
        prosess,
        items: this.controls,
        args: {
          selectDivision: this.selectDivision,
          selectFilterAffiliations: this.selectFilterAffiliations
        }
      };
      this.$router.push({ name: "ApprovalDetails", params });
    },
    selectDetails() {
      console.log("selectDetails");

      const prosess = "edit";
      const params = {
        title: this.title,
        prosess,
        items: this.controls,
        args: {
          targetItems: this.selected,
          selectDivision: this.selectDivision,
          selectFilterAffiliations: this.selectFilterAffiliations
        }
      };
      this.$router.push({ name: "ApprovalDetails", params });
    }
  },
  async created() {
    console.log("==created");
    await this.getData();
    await this.getSearchCondition();
    this.params = this.$route.params;
    if (this.params) {
      console.log("args:", this.params);
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style>
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
</style>
